import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d816676 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _7c51c8c6 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _5a7c6acc = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _1895ed7d = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _1db60942 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _dedb24e4 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _a131afc6 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _64b9f83e = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _178692da = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _0e373300 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _d4b988cc = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _52d4f236 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _b878c7bc = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _dcdeb600 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _a646b2b4 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _18f89381 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _2af6ce6a = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _74bf0fc1 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _21330aea = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _43d15148 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _0d816676,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _7c51c8c6,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _5a7c6acc,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _1895ed7d,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _1db60942,
    name: "receipt"
  }, {
    path: "/search",
    component: _dedb24e4,
    name: "search"
  }, {
    path: "/search-booking",
    component: _a131afc6,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _64b9f83e,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _178692da,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _0e373300,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _d4b988cc,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _52d4f236,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _b878c7bc,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _dcdeb600,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _a646b2b4,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _18f89381,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _2af6ce6a,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _74bf0fc1,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _21330aea,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _43d15148,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
